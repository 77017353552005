<template>
    <div class="container">
        <common-head :title="$t('tba.my_tuoguanxiangqing')" url="/new_trusteeship2" :query="query" :is-return="true"><!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> --></common-head>
        <div class="content">
            <div class="user_msg">
                <div class="msg">
                    <div class="msg-top">
                        <div class="msg_left">
                            <div class="img"><img :src="teacher_info.avatar" alt="" /></div>
                            <div class="name">{{ teacher_info.teacher_username }}</div>
                        </div>
                        <div class="msg_right">
                            <div class="VIP">
                                <div class="img_vip">
                                    <img src="@/assets/imgs/big_vip.png" alt="" v-if="teacher_info.teacher_level > 0" />
                                    <img src="@/assets/imgs/small_vip.png" alt="" v-else />
                                </div>
                                <div class="vip_number" v-if="teacher_info.teacher_level > 0">VIP{{ teacher_info.teacher_level }}</div>
                                <div class="vip_number" v-else>{{$t('tba.putongVIP')}}</div>
                            </div>
                            <div class="type">{{ $t('tba.fenchengbili') }} {{ teacher_info.brokerage_ratio*100 }}%</div>
                            <div class="type">{{ teacher_info.exchange && teacher_info.exchange.toUpperCase() }}—{{ type(teacher_info.deposit_type) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user_msg">
                <div class="shuju">
                    <div class="list1">
                        <div class="top_left">
                            <div class="left_num">{{ teacher_info.totalusdt | baoliu }} USDT</div>
                            <div class="txt">{{$t('tba.wodeleijishouyi')}}</div>
                        </div>
                    </div>
                    <div class="list1">
                        <div class="top_right">
                            <div class="left_num">{{ teacher_info.trusteeship_times }}</div>
                            <div class="txt">{{$t('tba.tuoguancishu')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user_msg">
                <div class="list">
                    <div class="list_item">{{$t('tba.mrsy')}}</div>
                    <van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow">
                        <van-row class="list_item" v-for="(item, index) in billList" :key="index">
                            <van-col span="15">
                                <div>{{ item.date }}</div>
                            </van-col>
                            <van-col span="9">
                                <div class="list-right">+{{ item.amount | baoliu }} {{ item.token }}</div>
                            </van-col>
                        </van-row>
                    </van-list>
                </div>
            </div>
            <div class="bottom"><div class="button" @click="show_tan()">{{$t('tba.woyaotuoguan')}}</div></div>
        </div>
        <van-popup v-model="show" round>
            <div class="tan1">
                <div class="tan_no_txt1">{{$t('tba.tuoguanzijin')}}</div>
                <div class="input"><input type="number" :placeholder="$t('tba.qingshuru')" v-model="number" /></div>
                <div class="tan_no_txt2">
                    <div class="left">{{$t('tba.zuidakeyongyue')}}:{{ amount }}USDT</div>
                    <div class="right" @click="quanbutuoguan()">{{$t('tba.quanbutuoguan')}}</div>
                </div>
                <div class="tan_no_button" @click="tuo()">{{$t('tba.querentuoguan')}}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
    components: { commonHead },
    data() {
        return {
            show: false,
            number: null,
            config_id: this.$route.query.config_id,
            amount: '',
            msg: this.$route.query.msg,
            teacher_info: {},
            billLoading: false,
            billFinished: false,
            billData: [],
            billList: [],
            billPage: 1,
            state: 1,
            query: {
                query: 0
            }
        };
    },
    created() {
        this.getTrusteeshipUserInfo();
        this.getBalance();
    },
    filters: {
        fencheng(v) {
            return v * 100;
        },
        baoliu(v) {
            return v ? parseFloat(v).toFixed(2) : '0.00';
        }
    },
    methods: {
		type(v) {
		    let a = '';
		    if (v == 1) {
		        a = this.$t('tba.xianhuojiaoyi');
		    } else if (v == 2) {
		        a = this.$t('tba.heyuejiaoyi');
		    }
		    return a;
		},
        // 获取-某一个-交易所的可用余额-传参-(交易所exchange=>1:huobi/2:bian/3:okex/4:uniswap)
        getBalance() {
            this.$post2('Robot/Api/Index/getBalance', {
                exchange: this.msg.exchange,
                noToast: true
            })
                .then(res => {
                    if (res.USDT.amount !== null) {
                        this.amount = res.USDT.amount;
                    }
                })
                .catch(e => {
                    // console.log("e222: ",e);
                    //     this.$toast.clear()
                });
        },
        //获取托管详情页 - 托管老师信息
        getTrusteeshipUserInfo() {
            this.$post2('Deposit/Api/Index/getTrusteeshipUserInfo', {
                config_id: this.config_id
            })
                .then(res => {
                    // console.log(res);
                    this.teacher_info = res;
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        show_tan() {
            this.show = !this.show;
        },
        tuo() {
            this.show = !this.show;
            this.state = 1;
            this.stopOrStartTrusteeshipUser();
        },
        quanbutuoguan() {
            this.number = this.amount;
        },
        //获取托管详情页 - 每日收益列表
        getTrusteeshipUserProfitList() {
            this.$post2('Deposit/Api/Index/getTrusteeshipUserProfitList', {
                config_id: this.config_id,
                page: this.billPage++,
                limit: 10
            })
                .then(res => {
                    // console.log(res);
                    this.billData = res.list;
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //用户暂停或者开启自己购买的某个托管套餐
        stopOrStartTrusteeshipUser() {
            this.$post2('Deposit/Api/Index/stopOrStartTrusteeshipUser', {
                state: this.state, //0 1
                id: this.teacher_info.trusteeship_user_id,
                escrow_funds: this.number
            })
                .then(res => {
                    // console.log(res);
                    this.$router.push({
                        path: '/my_trusteeshio_msg',
                        query: {
                            config_id: this.config_id
                        }
                    });
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        onLoadcashFlow() {
            this.getTrusteeshipUserProfitList();
            // 异步充值记录
            setTimeout(() => {
                for (let i = 0; i < this.billData.length; i++) {
                    this.billList.push(this.billData[i]);
                }
                // console.log(this.shouyiList);
                // 加载状态结束
                this.billLoading = false;
                // 数据全部加载完成

                console.log(this.billData);
                if (this.billData.length < 1) {
                    this.billFinished = true;
                }
            }, 1000);
        }
    }
};
</script>

<style scoped lang="less">
.container {
    background-color: #f8f8f8;
    // height: calc(100vh + 110px);
    height: 100%;
    // height: calc(100vh + 110px);
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    font-family: PingFang SC;
    .msg {
        padding: 0.4rem 0.2rem 0.16rem 0.2rem;
        .msg-top {
            display: flex;
            padding-bottom: 0.32rem;
            .msg_left {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 1.45rem;
                .img {
                    width: 1.2rem;
                    height: 1.2rem;
                    img {
                        border-radius: 0.5rem;
                        width: 100%;
                        height: 100%;
                    }
                }
                .name {
                    text-align: center;
                    color: #333333;
                    font-size: 0.28rem;
                    font-weight: 500;
                }
            }
            .msg_right {
                margin-left: 0.4rem;
                .time {
                    color: #333333;
                    font-size: 0.24rem;
                    font-weight: 500;
                }
                .VIP {
                    margin-top: 0.1rem;
                    display: flex;
                    align-items: center;
                    .img_vip {
                        // width: 0.24rem;
                        // height: 0.24rem;
                        img {
                            width: 0.252399rem;
                            height: 0.2218rem;
                        }
                    }
                    .vip_number {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        color: #333333;
                    }
                }
                .type {
                    margin-top: 0.1rem;
                    color: #5899f6;
                    background-color: #e8f1fe;
                    padding: 0.06rem 0.2rem 0.04rem 0.2rem;
                    font-size: 0.24rem;
                    text-align: center;
                    font-weight: 500;
					border-radius: 0.08rem;
                }
            }
        }
    }
    .shuju {
        padding: 0.28rem 0.68rem 0.28rem 0.68rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list1 {
            display: flex;
            align-items: center;
            .top_left {
                padding-left: 0.3rem;
                padding-right: 0.8rem;
                text-align: center;
                border-right: 0.02rem solid #b7d4ff;
            }
            .top_right {
                padding: 0 0.4rem;
                text-align: center;
            }
            .txt {
                color: #666666;
                font-size: 0.24rem;
                transform: scale(0.8);
            }
            .txt1 {
                color: #d98181;
                font-size: 0.24rem;
                transform: scale(0.8);
            }
            .left_num {
                text-align: center;
                color: #5998fc;
                font-size: 0.28rem;
            }
        }
    }
    .list {
        margin: 0 0.68rem;
        padding-bottom: 0.5rem;
        .list_item {
            padding: 0.18rem 0;
            border-bottom: 1px solid #e9e9e9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.28rem;
            color: #333333;
            font-weight: 500;
        }
        .list-right {
            text-align: right;
        }
        .list_item:last-child {
            padding-bottom: 0.5rem;
        }
    }
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0 0.28rem;
		width: 6.9rem;
		height: 0.88rem;
		line-height: 0.88rem;
        background-color: #5899f6;
        color: #ffffff;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.12rem;
        font-weight: 500;
    }
}
.tan1 {
    width: 5.6rem;
    height: 3.6rem;
    background-image: url(../../assets/imgs/tan_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 1.5rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 1.3rem 0.82rem 0 0.72rem;
        color: #333333;
        font-size: 0.2rem;
        text-align: left;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.16rem;
        transform: scale(0.75);
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #666666;
        text-align: left;
        .right {
            color: #d98181;
        }
    }
    .input {
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 0.04rem 0;
            font-size: 0.3rem;
        }
    }
    .tan_no_button {
        margin: 0.3rem 1.2rem 0 1.32rem;
        padding: 0.1rem 0.36rem 0.08rem 0.36rem;
        font-size: 0.28rem;
        color: #ffffff;
        background-color: #5899f6;
        text-align: center;
        border-radius: 0.16rem;
    }
}
</style>
